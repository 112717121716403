<template>
    <div v-if="user" class="profile__detail">

        <p class="text-center">Bitte beschreibe uns dein Probelm so detailliert wie möglicht, damit wir dir bei der Lösung bestmöglich helfen können:</p>

        <v-text-field v-if="requireEmailInput" type="text" v-model="emailAddress" placeholder="Deine Kontaktadresse"></v-text-field>
        
        <textarea v-model="message" type="text" placeholder="Beschreibung..." rowx="0"></textarea>

        <v-btn class="button button--primary" @click="reportProblem">Nachricht senden</v-btn>

    </div>
</template>
<script>
import firebase from 'firebase';
import {userFeedbackCollection} from '@/firebase/index.js';

export default {
    name: 'SupportProblem',
    data() {
        return {
            user: null,
            message: null,
            emailAddress: null,
            requireEmailInput: false,
        }
    },
    methods: {
        init(){
            this.user = firebase.auth().currentUser;
            if (this.user && this.user.email.indexOf("privaterelay.appleid.com") > -1) {
                this.requireEmailInput = true;
            }
        },
        reportProblem() {
            let currentDate = new Date();
            let problem = {
                message: this.message,
                time: currentDate.getTime(),
                timeAsString: currentDate.toString(),
                userId: this.user.uid,
                email: this.requireEmailInput ? this.emailAddress : this.user.email,
                displayName: this.user.displayName,
            }
            userFeedbackCollection.add(problem);

            this.$router.go(-1);
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style lang="scss">
    .profile__detail {
        textarea {
            flex: 1;
            margin: 8px 0 32px;
            font-size: 14px;
            padding: 10px 16px !important;
        }
    }
</style>